import React from 'react';
import{Image } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faTimes } from "@fortawesome/free-solid-svg-icons";

const UserConnection = ({user,onLogin,onLogout}) => {
  if(!user ){
	return (		
		<FontAwesomeIcon
			icon={faUser}
			onClick={onLogin}
			color="white"
			size="lg"
		/>

  	);    
  }


  return (
    <div id="user-connection-logout" onClick={onLogout}>
		<Image roundedCircle src={user.photoURL} />
      	<FontAwesomeIcon icon={faTimes} color="red" />      
    </div>
  );

}

export default UserConnection;
