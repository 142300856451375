import { combineReducers } from 'redux';

import projects from './projects';
import modules from './modules';
import functions from './functions';
import user from './user';
import global from './global';

const reducer = combineReducers({
	projects,
	modules,
	functions,
	user,
	global,
});

export default reducer;
