import React, { Component } from 'react';
import ProjectChargeCalculatorApp from './containers/App.js';
import UserConnection from './containers/UserConnection';
import {
  Container,
  Navbar,
  Nav,
  NavItem,
  Row,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Navbar
          bg="dark"
          variant="dark"
          expand="lg"
          className="justify-content-between"
        >
          <Navbar.Brand>
            <FontAwesomeIcon icon={faDollarSign} />
            	hargelator
          	</Navbar.Brand>
          <Nav>
            <NavItem id="user-box">
              <UserConnection />
            </NavItem>
          </Nav>
        </Navbar>
        <br />
        <Container fluid>
          <Row>
            <Col xs={{ span: 10, offset: 1 }} >
              <ProjectChargeCalculatorApp />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default App;
