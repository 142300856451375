import React, { useEffect } from 'react';
import { Button, ButtonGroup, Row, Col, Alert, Nav, Jumbotron, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faUndo, faSave, faPlus } from "@fortawesome/free-solid-svg-icons";
import Module from '../containers/Module.js';
import Global from '../containers/Global.js';
import ParamCoef from '../containers/ParamCoef.js';
import '../assets/css/grid-overview.css';

// hors de la constante pour quelle soit gardé en mémoire
let counter = 0;

const App = ({ user, project, global, cost, leavingCost, globalCost, modules, onAddModule, onSetProjectLabel, onSaveAsJson, onReset, onToggleShowParam, setIsDragging, onLoadJson, onSetCurrentTab, onSaveProject }) => {
  const mods = modules.map((module) => <Module key={module.get('id')} id={module.get('id')} />);
  const param = project.get('isParamShown') ? <ParamCoef /> : "";

  // Block la fermeture de la page si on n'a pas sauvegardé
  useEffect(() => {
    function confirmExit() {
      if (global.get("toSave")) {
        console.log("show warning");
        return "show warning";
      }
      console.log("don't show warning");
      return null;
    }
    window.onbeforeunload = confirmExit;

  });

  // MAJ du title de la vue 
  useEffect(() => {
    document.title = `Chargelator -  ${project.get("label")}`;
  });

  const renderDroppingZone = () => {
    return (<Alert variant="info">
      <h4>Vous pouvez glisser votre fichier pour qu il soit pris en compte</h4>
    </Alert>);
  }

  const loadJson = (evt) => {
    evt.preventDefault();
    counter = 0;

    var reader = new FileReader();
    reader.addEventListener("load", function () {
      let data = null;
      try {
        data = JSON.parse(reader.result);
      } catch (e) {
        console.log('error parsing JSON');
        console.log(reader.result);
        return;
      }

      onLoadJson(data);

    })


    try {
      reader.readAsText(evt.dataTransfer.files[0]);
    } catch (e) {
      console.log("error reading file");
      return;
    }

    setIsDragging(false);
  };
  const dragOver = (evt) => {
    evt.preventDefault();
  };
  const dragEnter = (evt) => {
    evt.preventDefault();
    counter++;
    if (counter === 1) {
      setIsDragging(true);
    }
    return false;
  };
  const dragLeave = (evt) => {
    evt.preventDefault();
    counter--;
    if (counter <= 0) {
      setIsDragging(false);
    }
    return false;
  };

  const renderTab = (tab) => {
    if (parseInt(tab) === 1) {
      return (
        <Row>
          <Col xs={project.get("isParamShown") ? 9 : 12}>
            <Container fluid>
              <Row>
                <Button onClick={onAddModule}>
                  <FontAwesomeIcon icon={faPlus} /> Ajouter un module
                </Button>
              </Row>
              {mods}
            </Container>
          </Col>
          {param}
        </Row>
      );
    } else {
      return <Global />;
    }
  }

  const droppingAlert = project.get('isDragging') ? renderDroppingZone() : '';

  if (!user) {
    return (
      <Jumbotron>
        <h1>Bienvenue sur l'application Chargelator !!!</h1>
        <p>
          Veuillez-vous connecter pour accéder à l'application !
				</p>
      </Jumbotron>
    );
  }

  // calcul du texte du nombre JEH restant
  let leavingCostElement = null;
  if (leavingCost !== cost) {
    leavingCostElement = `(${leavingCost} JEH restant${leavingCost > 1 ? "s" : ""})`;
  }


  return (
    <div
      onDrop={loadJson}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
    >
      {droppingAlert}
      <Row>
        <Col xs={10}>
          <h1>
            <input
              type="text"
              value={project.get("label")}
              onChange={(evt) => {
                onSetProjectLabel(evt.target.value);
              }}
            />
          </h1>
        </Col>
        <Col xs={2}>
          <ButtonGroup>
            <Button
              title="Show/hide parameters"
              className="pull-right"
              onClick={onToggleShowParam}
            >
              <FontAwesomeIcon icon={faCogs} />
            </Button>
            <Button title="Save" variant={global.get('toSave') ? "warning" : "success"} className="pull-right" onClick={() => { onSaveAsJson(); onSaveProject(); }}>
              <FontAwesomeIcon icon={faSave} />
            </Button>
            <Button title="Reset" className="pull-right" onClick={onReset}>
              <FontAwesomeIcon icon={faUndo} />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul className="grid-overview grid-overview-2">
            <li>
              <span className="name">Cout de réalisation</span>
              <span className="value">{cost} JEH {leavingCostElement} </span>
            </li>
            <li>
              <span className="name">Cout global</span>
              <span className="value">{globalCost} JEH</span>
            </li>
          </ul>
        </Col>
      </Row>
      <Nav
        variant="tabs"
        defaultActiveKey={project.get("currentTab")}
        justify
        fill
        onSelect={onSetCurrentTab}
      >
        <Nav.Item>
          <Nav.Link eventKey={1}>Charges de réalisation</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={2}>Charges globales</Nav.Link>
        </Nav.Item>
      </Nav>
      <br />
      {renderTab(project.get("currentTab"))}
    </div>
  );
};

export default App;
