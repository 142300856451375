import React from 'react';
import { Card, Table, Button, Container, Row, Col, ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Function from '../containers/Function.js';
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import '../assets/css/Module.css';
import { faPlus, faTrash, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const moduleDetail = (module, fonctions, isShown, onMoveFunction) => {
  if (!isShown) {
    return ''
  }

  /**
   * Callback suite a DnD d'une function
   * @param {*} param0 
   */
  const onDragEnd = ({ draggableId, destination }) => {
    if (!destination) {
      return;
    }
    const { index } = destination;

    return onMoveFunction(draggableId, index);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Table responsive striped bordered className="moduleTable">
        <thead>
          <tr>
            <th rowSpan={2}>#</th>
            <th rowSpan={2}>Fonctionnalité</th>
            <th colSpan={4}>IHM</th>
            <th colSpan={4}>Traitement</th>
            <th rowSpan={2}><b>Cout</b></th>
            <th rowSpan={2}></th>
          </tr>
          <tr>
            <th><span title="Faible">--</span></th>
            <th><span title="Simple">-</span></th>
            <th><span title="Moyen">+</span></th>
            <th><span title="Complexe">++</span></th>
            <th><span title="Faible">--</span></th>
            <th><span title="Simple">-</span></th>
            <th><span title="Moyen">+</span></th>
            <th><span title="Complexe">++</span></th>

          </tr>
        </thead>
        <Droppable droppableId={module.get("id")}>
          {(provided, snapshot) => (
            <tbody
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {fonctions}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </Table>
    </DragDropContext>
  );
}

const Module = ({ module, cost, leavingCost, percentage, functions, onAddFunction, onRemoveModule, onSetModuleLabel, onToggleShowModule, onMoveFunction }) => {
  const fonctions = functions.map((func, index) => <Function key={func} index={index} id={func} />);

  // calcul de l'élement indiquant le nombre de JEH Restant
  let leavingComponent = null
  if (leavingCost === 0) {
    leavingComponent = <Col className="text-success" xs={2}> Terminé </Col>
  } else if (cost > leavingCost) {
    leavingComponent = <Col xs={2}> {leavingCost} JEH restant{leavingCost > 1 ? "s" : ""} </Col>
  }


  return (
    <Card className="projectChargeCalculatorModule m-1">
      <Card.Header>
        <ButtonToolbar className="float-right">
          <ButtonGroup>
            <Button onClick={onAddFunction} variant="primary" className="mx-1">
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <Button onClick={() => onRemoveModule(functions)} variant="danger">
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
        <ButtonGroup className="float-left">
          <Button onClick={onToggleShowModule}>
            <FontAwesomeIcon
              icon={module.get("isShown") ? faChevronUp : faChevronDown}
            />
          </Button>
        </ButtonGroup>
        <Container>
          <Row>
            <Col xs={leavingComponent !== null ? 8 : 10}>
              <input
                type="text"
                value={module.get("name")}
                onChange={(evt) => {
                  onSetModuleLabel(evt.target.value);
                }}
              />
            </Col>
            <Col xs={1}>{cost} JEH</Col>
            {leavingComponent}
            <Col xs={1}>{Math.round(percentage * 1000) / 10} %</Col>
          </Row>
        </Container>
      </Card.Header>
      {moduleDetail(module, fonctions, module.get("isShown"), onMoveFunction)}
    </Card>
  );
};


export default Module;
