import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
import {createStore} from 'redux';
import rootReducer  from './reducers';
import "bootstrap/dist/css/bootstrap.min.css";
import {setUser} from './actions';
import {auth} from './firebase';

// Sentry integration 
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
	dsn: "https://8f15ddce930441c4bf6efe5e426bfe45@o560935.ingest.sentry.io/5702962",
	integrations: [new Integrations.BrowserTracing()],
  
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
  });


let store = createStore(rootReducer);

ReactDOM.render(
		<Provider store={store}>
  			<App />
		</Provider>,
  document.getElementById('root')
);

auth.onAuthStateChanged((user) => {
	store.dispatch(setUser(user));
});
