import { connect } from 'react-redux';
import { setProjectLabel, addModule, resetProject, toggleShowParam, setIsDragging, loadJson, setCurrentTab, saveProject } from '../actions';
import AppComponent from '../components/App';
import Utils from '../Utils/Utils';
import FileSaver from 'file-saver';

const mapStateToProps = (state, { id }) => {
	const modules = state.modules.allIds.map(id => state.modules.byId.get(id));
	const { projects, global } = state;

	const { cost: realCost, leavingCost, totalAfterMarge: globalCost } = Utils.getGlobalCost(state);
	return {
		global,
		leavingCost,
		user: state.user.get('user'),
		modules: modules,
		project: projects,
		cost: realCost,
		globalCost: globalCost,
		onSaveAsJson: () => {
			// création du fichier
			var file = new File([JSON.stringify(state)],
				state.projects.get('label') + ".json",
				{ type: "text/plain;charset=utf-8" });
			// sauvegarde pour l'user
			FileSaver.saveAs(file);
		}
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSetProjectLabel: label => {
			dispatch(setProjectLabel(label));
		},
		onAddModule: () => {
			dispatch(addModule());
		},
		onReset: () => {
			dispatch(resetProject());
		},
		onToggleShowParam: () => {
			dispatch(toggleShowParam());
		},
		setIsDragging: isDragging => {
			dispatch(setIsDragging(isDragging));
		},
		onLoadJson: data => {
			dispatch(loadJson(data));
		},
		onSetCurrentTab: id => {
			dispatch(setCurrentTab(id));
		},
		onSaveProject: () => {
			// création du fichier      
			dispatch(saveProject());
		},
	};
};

const App = connect(
	mapStateToProps,
	mapDispatchToProps
)(AppComponent);

export default App;
