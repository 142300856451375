import { connect } from 'react-redux';
import { setModuleName, removeModule, addFunction, toggleShowModule, moveFunction } from '../actions';
import Utils from '../Utils/Utils';
import ModuleComponent from '../components/Module';

const mapStateToProps = (state, { id }) => {
	const module = state.modules.byId.get(id);
	const functions = module.get('fncs');
	const functionsCosts = functions.map(fnc => state.functions.byId.get(fnc).get('costs')).toJS();
	const functionsLeaving = functions.filter(fnc => state.functions.byId.get(fnc).get("done") !== true).map(fnc => state.functions.byId.get(fnc).get('costs')).toJS();
	const param = state.projects.get('costs').toJS();
	const { cost: globalCost } = Utils.getGlobalCost(state);

	const moduleCost = Utils.getFunctionsCost(functionsCosts, param);
	const moduleLeavingCost = Utils.getFunctionsCost(functionsLeaving, param);

	return {
		module: module,
		functions: functions,
		cost: moduleCost,
		leavingCost: moduleLeavingCost,
		percentage: (globalCost === 0 ? 0 : (moduleCost / globalCost))
	};
};

const mapDispatchToProps = (dispatch, { id }) => {
	return {
		onSetModuleLabel: (label) => {
			dispatch(setModuleName(id, label));
		},
		onRemoveModule: (functions) => {
			dispatch(removeModule(id, functions));
		},
		onAddFunction: () => {
			dispatch(addFunction(id));
		},
		onToggleShowModule: () => {
			dispatch(toggleShowModule(id));
		},
		onMoveFunction: (id, index) => {
			dispatch(moveFunction(id, index));
		}

	};
};

const Module = connect(
	mapStateToProps,
	mapDispatchToProps
)(ModuleComponent);

export default Module;
