import { SAVE_PROJECT, ADD_FUNCTION, ADD_MODULE, REMOVE_FUNCTION, REMOVE_MODULE, SET_FUNCTION_COSTS, SET_MODULE_NAME, SET_PROJECT_COSTS, SET_PROJECT_PARAM, SET_CONNEXE_COST, RESET_PROJECT, SET_FUNCTION_LABEL, SET_PROJECT_LABEL, MOVE_FUNCTION, SET_FUNCTION_DONE } from '../actions';
import { fromJS } from 'immutable';

function getDefaultState() {
	return fromJS({
		toSave: false
	});
}

function setIsToSave(state) {
	return state.set("toSave", true);
}

function setIsSaved(state) {
	return state.set("toSave", false);
}


const Reducer = (state = null, action) => {
	if (state === null) {
		state = getDefaultState();
	}
	switch (action.type) {
		case SAVE_PROJECT:
		case RESET_PROJECT:
			return setIsSaved(state);
		case ADD_FUNCTION:
		case ADD_MODULE:
		case REMOVE_FUNCTION:
		case REMOVE_MODULE:
		case SET_FUNCTION_COSTS:
		case SET_FUNCTION_LABEL:
		case SET_FUNCTION_DONE:
		case SET_MODULE_NAME:
		case SET_PROJECT_COSTS:
		case SET_PROJECT_LABEL:
		case SET_PROJECT_PARAM:
		case SET_CONNEXE_COST:
		case MOVE_FUNCTION:
			return setIsToSave(state);
		default:
			return getDefaultState();
	}
};

export default Reducer;
