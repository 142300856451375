import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Draggable } from "react-beautiful-dnd";
import "../assets/css/Function.css";

const Function = ({ fnc, cost, onSetFunctionLabel, onSetFunctionCosts, onRemoveFunction, onSetFunctionDone, index }) => {
  const handleChangeCost = (cost, isIhm, index) => {
    const newCosts = fnc.get('costs').update(isIhm ? 'ihm' : 'traitement', v => v.update(index, () => parseInt(cost, 10)));

    // on signale le changement au parent
    onSetFunctionCosts(newCosts);
  };

  return (
    <Draggable draggableId={fnc.get("id")} index={index}>
      {provided => (
        <tr className={"fctRow" + (fnc.get('done') ? " fct-done" : "")} ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <td className="fctRowId">
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" id={`switch_${fnc.get("id")}`} checked={fnc.get("done")} onChange={evt => onSetFunctionDone(evt.target.checked)} />
              <label className="custom-control-label" htmlFor={`switch_${fnc.get("id")}`}></label>
            </div>
          </td>
          <td className="fctRowName">
            <input
              value={fnc.get("name")}
              type="text"
              onChange={(evt) => onSetFunctionLabel(evt.target.value)}
            />
          </td>
          <td className="fctRowValue">
            <input
              type="number"
              min={0}
              value={fnc.get("costs").get("ihm").get(0)}
              onChange={(evt) => handleChangeCost(evt.target.value, true, 0)}
            />
          </td>
          <td className="fctRowValue">
            <input
              type="number"
              min={0}
              value={fnc.get("costs").get("ihm").get(1)}
              onChange={(evt) => handleChangeCost(evt.target.value, true, 1)}
            />
          </td>
          <td className="fctRowValue">
            <input
              type="number"
              min={0}
              value={fnc.get("costs").get("ihm").get(2)}
              onChange={(evt) => handleChangeCost(evt.target.value, true, 2)}
            />
          </td>
          <td className="fctRowValue">
            <input
              type="number"
              min={0}
              value={fnc.get("costs").get("ihm").get(3)}
              onChange={(evt) => handleChangeCost(evt.target.value, true, 3)}
            />
          </td>
          <td className="fctRowValue">
            <input
              type="number"
              min={0}
              value={fnc.get("costs").get("traitement").get(0)}
              onChange={(evt) => handleChangeCost(evt.target.value, false, 0)}
            />
          </td>
          <td className="fctRowValue">
            <input
              type="number"
              min={0}
              value={fnc.get("costs").get("traitement").get(1)}
              onChange={(evt) => handleChangeCost(evt.target.value, false, 1)}
            />
          </td>
          <td className="fctRowValue">
            <input
              type="number"
              min={0}
              value={fnc.get("costs").get("traitement").get(2)}
              onChange={(evt) => handleChangeCost(evt.target.value, false, 2)}
            />
          </td>
          <td className="fctRowValue">
            <input
              type="number"
              min={0}
              value={fnc.get("costs").get("traitement").get(3)}
              onChange={(evt) => handleChangeCost(evt.target.value, false, 3)}
            />
          </td>
          <td className="fctRowCost">
            <b>{cost}</b>
          </td>
          <td className="fctRowDelete">
            <Button variant="danger" onClick={() => onRemoveFunction()}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </td>
        </tr>
      )}
    </Draggable>
  );
};

export default Function;
